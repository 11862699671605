import React from "react"
import { Link } from "gatsby"
import "./style.scss"

const ExistingUserLink = ({ data }) => {
  return (
    <div className="login-link">
      <Link to="/login">Existing User? Log in Here</Link>
    </div>
  )
}
export default ExistingUserLink
