import React from "react"

import SEO from "@components/seo"
import * as data from "@data/pledge"
import HeaderandText from "@components/header_with_text"
import Layout from "@components/layout"
import BackButton from "@components/back_button"
import RegForm from "@components/form_parent_registration"
import ExistingUserLink from "@components/existing_user_link"

const PledgeRegistrationPage = () => {
  const { parentReg } = data
  return (
    <Layout type="gray" pageClass="pledge">
      <BackButton
        data={parentReg.backLink}
        backLinkNative={parentReg.backLinkNative}
      />
      <SEO title={parentReg.title} />
      <HeaderandText data={parentReg.heading} />
      <RegForm data={parentReg.regButton} />
      <ExistingUserLink />
    </Layout>
  )
}

export default PledgeRegistrationPage
