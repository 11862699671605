import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import * as data from "@data/pledge"
import { validate } from "@data/validations"
import * as progress from "@services/progress"
import * as errors from "@services/errors"
import * as beneficiaryToken from "@services/beneficiaryToken"
import LAEJS from "@services/LAEJS"
import InputPassword from "@components/_molecules/input_password"


class RegForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      emailErrorText: "Invalid email",
      emailErrorStatus: false,
      password: "",
      passwordErrorText: "Min 8 characters, 1 uppercase, 1 lowercase, 1 number",
      passwordErrorStatus: false,
      buttonSubmitted: false,
      generalError: null
    }
  }

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value,
      [`${ev.target.name}ErrorStatus`]: false,
      generalError: null
    })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    let _this = this //needed to avoid losing context during axios/fetch call
    let { beneficiaryToken } = localStorage

    let emailErrorStatus = !validate.email.test(_this.state.email),
      passwordErrorStatus = !validate.password.test(_this.state.password)
    _this.setState({ emailErrorStatus, passwordErrorStatus })
    let createCustodianUserInput = {}
    createCustodianUserInput = {
      user: {
        email: _this.state.email,
        password: _this.state.password
      }
    }
    if (beneficiaryToken) {
      createCustodianUserInput.user.registrationParams = {
        custodialRegistrationCode: beneficiaryToken
      }
    }
    if (!emailErrorStatus && !passwordErrorStatus) {
      _this.setState({ buttonSubmitted: true })
      let registrationQuery = {
        query: `mutation($createCustodianUserInput: CreateCustodianUserInput!) {
          createCustodianUser(input: $createCustodianUserInput) {
            user {
              id
              email
              phone
              registrationCompletedAt
              phoneVerificationStatus
              token
            }
          }
        }`,
        variables: {
          createCustodianUserInput
        }
      }
      const options = {
        method: "POST",
        data: JSON.stringify(registrationQuery),
        headers: headers.getHeaders(),
        url: headers.api
      }
      axios(options)
        .then(res => {
          if (res.data.errors) {
            _this.errorHandler(res.data.errors[0].message)
          } else {
            if (typeof window !== `undefined`)
              window.localStorage.setItem(
                `elsi`,
                res.data.data.createCustodianUser.user.token
              )
            localStorage.setItem("parentEmail", _this.state.email)
            progress.updateProgress()

            LAEJS.jsSetUserIsCustodian()

            setTimeout(function() {
              window.location = `/pledge/register/your-info`
            }, 2000)
          }
        })
        .catch(error => {
          this.setState({ buttonSubmitted: false })
          console.log(error)
        })
    }
  }

  errorHandler = err => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err)
    })
  }

  render() {
    const { parentReg } = data
    return (
      <div className="registration-form">
        <form onSubmit={this.handleSubmit}>
          <InputLabelState
            data={{
              name: `Email`,
              type: `email`,
              helpText: this.state.emailErrorText,
              errorStatus: this.state.emailErrorStatus,
              defaultVisibility: 0,
              value: this.state.email
            }}
            onChange={this.handleChange.bind(this)}
          />
          <InputPassword
            data={{
              name: `Password`,
              helpText: this.state.passwordErrorText,
              errorStatus: this.state.passwordErrorStatus,
              defaultVisibility: 1,
              value: this.state.password
            }}
            onChange={this.handleChange.bind(this)}
          />
          <div style={{ width: `100%`, height: `3rem` }}></div>
          <GeneralSubmitMessage error={this.state.generalError} />
          <FormButton
            data={parentReg.regButton}
            isDisabled={this.state.buttonSubmitted}
          />
        </form>
      </div>
    )
  }
}

export default RegForm
